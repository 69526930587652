<template>
    <div>
        <el-table
            :data="inventory"
            border
            show-summary
            :summary-method="getSummaries"
            :cell-class-name="({row, column, rowIndex, columnIndex})=>checkStateRound(row, column, rowIndex, columnIndex)"
            sum-text="Total Stock"
            empty-text='No Data'
            class="width-max">
            <el-table-column
              prop="product"
              label="Product list"
              header-align="center"
            >
            </el-table-column>
            <el-table-column label="Round 1" header-align="center">
              <InventoryList round="round1" :userLevel="userLevel"/>
            </el-table-column>
            <el-table-column label="Round 2" header-align="center">
              <InventoryList round="round2" :userLevel="userLevel"/>
            </el-table-column>
            <el-table-column label="Round 3" header-align="center">
              <InventoryList round="round3" :userLevel="userLevel"/>
            </el-table-column>
            <el-table-column label="Round 4" header-align="center">
              <InventoryList round="round4" :userLevel="userLevel"/>
            </el-table-column>
            <el-table-column label="Round 5" header-align="center">
              <InventoryList round="round5" :userLevel="userLevel"/>
            </el-table-column>
            <el-table-column label="Round 6" header-align="center">
              <InventoryList round="round6" :userLevel="userLevel"/> 
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { HTTP } from'@/service/axios'
import InventoryList from './InventoryList';
export default {
    props:{
      activeTab:{
        type:String,
        default(){
          return ''
        }
      },
      userId:{
        type:String,
        default(){
          return ''
        }
      },
      userLevel:{
          type:Number,
          default(){
              return 0
          }
      }
    },
    components:{
      InventoryList
    },
    computed: {
      user(){
          return this.$store.state.user
      },
      room(){
          return this.$store.state.room
      },
    },
    watch: {
      activeTab(){
              if(this.activeTab === 'inventory'){
                  this.fetchInventory()
              }
      }
    },
    data() {
      return {
        inventory:[],
      };
    },
    mounted() {
      this.fetchInventory()
    },
    methods: {
      fetchInventory(){
        HTTP.get(`inv/${this.userId}/${this.room._id}`)
        .then(res => {
  
          if (res.data.success) {
            let inventoryArray = []
            let whiteRice = this.setDataInventory(res.data.obj.whiteRice,'ข้าวสารขาว')
            inventoryArray.push(whiteRice)
            let jasmineRice = this.setDataInventory(res.data.obj.jasmineRice,'ข้าวสารหอม')
            inventoryArray.push(jasmineRice)
            let whiteRiceCan = this.setDataInventory(res.data.obj.whiteRiceCan,'ข้าวขาวนึ่งกระป๋อง')
            inventoryArray.push(whiteRiceCan)
            let jasmineRiceCan = this.setDataInventory(res.data.obj.jasmineRiceCan,'ข้าวหอมนึ่งกระป๋อง')
            inventoryArray.push(jasmineRiceCan)
            let riceBranOil = this.setDataInventory(res.data.obj.riceBranOil,'น้ำมันรำข้าวสกัดบริสุทธิ์')
            inventoryArray.push(riceBranOil)
            let riceEnergyDrink = this.setDataInventory(res.data.obj.riceEnergyDrink,'เครื่องดื่มข้าวชูกำลัง')
            inventoryArray.push(riceEnergyDrink)
            this.inventory = inventoryArray
          }
        })
        .catch(e => {
          console.log("SET_INVENTORY error",e);
        });
      },
      setDataInventory(data,name){
          var room = this.room
          let inventoryRound = {
            product: name,
            round1:setData(data,0),
            round2:setData(data,1),
            round3:setData(data,2),
            round4:setData(data,3),
            round5:setData(data,4),
            round6:setData(data,5)
          }

          return inventoryRound

          function setData(item,index){
 
            if(item.length > 0 && typeof item[index] !== 'undefined' && room.currentRound > index){
              let obj = {
                begin:item[index].begin,
                sale:item[index].sale,
                damaged:item[index].damaged,
                manufacture:item[index].manufacture
              }
              return obj
            }else{
              let obj = {
                begin:0,
                sale:0,
                damaged:0,
                manufacture:0
              }
              return obj
            } 
          }
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total stock';
            return;
          }
          const values = data.map(item => (typeof column.property !== 'undefined')?Number(calLastAmount(item[column.property])):undefined);
     
          if (!values.every(value => isNaN(value))) {
            let sum =  values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sum
          } else {
            sums[index] = '';
          }
        });

        return sums;

        function calLastAmount(item){
            return item.begin - (item.sale + item.damaged) + item.manufacture 
        }
    },
    checkStateRound(row, column, rowIndex, columnIndex){

      if((this.room.currentRound*2) === columnIndex || ((this.room.currentRound*2)-1) === columnIndex){
         return 'cell-round'
      }else if(columnIndex !== 0 && columnIndex > this.room.currentRound*2){
        return 'header-row'
      }
    }
  }
};
</script>