<template>
    <div>
       <el-table
            :data="accounting"
            border
            show-summary
            v-loading="loading"
            empty-text='No Data'
            :summary-method="getSummaries"
            :cell-class-name="({row, column, rowIndex, columnIndex})=>disbledRound(row, column, rowIndex, columnIndex)"
            :header-cell-class-name="({row, rowIndex})=>{return 'header-row'}"
            class="width-max">
            <el-table-column
              prop="name"
              label="List"
              header-align="center"
              width="400"
            >
            </el-table-column>
            <el-table-column 
              prop="round1" 
              label="Round 1" 
              header-align="center"
              align="right">
              <template slot-scope="scope">
                <div v-if="scope.row.id !== 14">
                  <span :class="getColorMoney(scope.row.statusMoney,scope.row.round1)" >
                    <span v-show="scope.row.round1 > 0" >{{scope.row.statusMoney}}</span> 
                    {{scope.row.round1 | comma}}
                  </span>
                </div>
                <div v-else>
                  <el-popover trigger="hover" placement="top" width="300" :disabled="scope.row.round1.length === 0">
                  
                    <p v-for="(item,index) in scope.row.round1" :key="index"> <span> {{ item.becName }}</span>  
                      <span v-if="checkActionEvent(item.becId)" class="mg-l-5">{{item.action}}</span> 
                      <sup :class="checkActionEvent(item.becId)?'color-success':'color-danger'">{{' [จากรอบที่ '+item.round+']'}}</sup>
                    </p>
                    <div slot="reference" class="d-inline">
                      
                      <span  :class="getColorMoney(scope.row.statusMoney,sumEventMoney(scope.row.round1))" >
                        <span v-show="scope.row.round1.length > 0  && sumEventMoney(scope.row.round1) > 0" >{{scope.row.statusMoney}}</span> 
                        {{sumEventMoney(scope.row.round1) | comma}}
                      </span>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column 
              prop="round2" 
              label="Round 2" 
              header-align="center"
              align="right">
              <template slot-scope="scope">
                <div v-if="scope.row.id !== 14">
                  <span :class="getColorMoney(scope.row.statusMoney,scope.row.round2)" >
                    <span v-show="scope.row.round2 > 0" >{{scope.row.statusMoney}}</span> 
                    {{scope.row.round2 | comma}}
                  </span>
                </div>
                <div v-else>
                  <el-popover trigger="hover" placement="top" width="300" :disabled="scope.row.round2.length === 0">
                  
                    <p v-for="(item,index) in scope.row.round2" :key="index"> <span> {{ item.becName }}</span>  
                      <span v-if="checkActionEvent(item.becId)" class="mg-l-5">{{item.action}}</span> 
                      <sup :class="checkActionEvent(item.becId)?'color-success':'color-danger'">{{' [จากรอบที่ '+item.round+']'}}</sup>
                    </p>
                    <div slot="reference" class="d-inline">
                      
                      <span  :class="getColorMoney(scope.row.statusMoney,sumEventMoney(scope.row.round2))" >
                        <span v-show="scope.row.round2.length > 0  && sumEventMoney(scope.row.round2) > 0" >{{scope.row.statusMoney}}</span> 
                        {{sumEventMoney(scope.row.round2) | comma}}
                      </span>
                    </div>
                  </el-popover>
                </div>
            </template>
            </el-table-column>
            <el-table-column 
              prop="round3" 
              label="Round 3" 
              header-align="center"
              align="right">
              <template slot-scope="scope">
                <div v-if="scope.row.id !== 14">
                  <span :class="getColorMoney(scope.row.statusMoney,scope.row.round3)" >
                    <span v-show="scope.row.round3 > 0" >{{scope.row.statusMoney}}</span> 
                    {{scope.row.round3 | comma}}
                  </span>
                </div>
                <div v-else>
                  <el-popover trigger="hover" placement="top" width="300" :disabled="scope.row.round3.length === 0">
                  
                    <p v-for="(item,index) in scope.row.round3" :key="index"> <span> {{ item.becName }}</span>  
                      <span v-if="checkActionEvent(item.becId)" class="mg-l-5">{{item.action}}</span> 
                      <sup :class="checkActionEvent(item.becId)?'color-success':'color-danger'">{{' [จากรอบที่ '+item.round+']'}}</sup>
                    </p>
                    <div slot="reference" class="d-inline">
                      
                      <span  :class="getColorMoney(scope.row.statusMoney,sumEventMoney(scope.row.round3))" >
                        <span v-show="scope.row.round3.length > 0  && sumEventMoney(scope.row.round3) > 0" >{{scope.row.statusMoney}}</span> 
                        {{sumEventMoney(scope.row.round3) | comma}}
                      </span>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column 
              prop="round4" 
              label="Round 4" 
              header-align="center"
              align="right">
             <template slot-scope="scope">
                <div v-if="scope.row.id !== 14">
                  <span :class="getColorMoney(scope.row.statusMoney,scope.row.round4)" >
                    <span v-show="scope.row.round4 > 0" >{{scope.row.statusMoney}}</span> 
                    {{scope.row.round4 | comma}}
                  </span>
                </div>
                <div v-else>
                  <el-popover trigger="hover" placement="top" width="300" :disabled="scope.row.round4.length === 0">
                  
                    <p v-for="(item,index) in scope.row.round4" :key="index"> <span> {{ item.becName }}</span>  
                      <span v-if="checkActionEvent(item.becId)" class="mg-l-5">{{item.action}}</span> 
                      <sup :class="checkActionEvent(item.becId)?'color-success':'color-danger'">{{' [จากรอบที่ '+item.round+']'}}</sup>
                    </p>
                    <div slot="reference" class="d-inline">
                      
                      <span  :class="getColorMoney(scope.row.statusMoney,sumEventMoney(scope.row.round4))" >
                        <span v-show="scope.row.round4.length > 0  && sumEventMoney(scope.row.round4) > 0" >{{scope.row.statusMoney}}</span> 
                        {{sumEventMoney(scope.row.round4) | comma}}
                      </span>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column 
              prop="round5" 
              label="Round 5" 
              header-align="center"
              align="right">
              <template slot-scope="scope">
                <div v-if="scope.row.id !== 14">
                  <span :class="getColorMoney(scope.row.statusMoney,scope.row.round5)" >
                    <span v-show="scope.row.round5 > 0" >{{scope.row.statusMoney}}</span> 
                    {{scope.row.round5 | comma}}
                  </span>
                </div>
                <div v-else>
                  <el-popover trigger="hover" placement="top" width="300" :disabled="scope.row.round5.length === 0">
                  
                    <p v-for="(item,index) in scope.row.round5" :key="index"> <span> {{ item.becName }}</span>  
                      <span v-if="checkActionEvent(item.becId)" class="mg-l-5">{{item.action}}</span> 
                      <sup :class="checkActionEvent(item.becId)?'color-success':'color-danger'">{{' [จากรอบที่ '+item.round+']'}}</sup>
                    </p>
                    <div slot="reference" class="d-inline">
                      
                      <span  :class="getColorMoney(scope.row.statusMoney,sumEventMoney(scope.row.round5))" >
                        <span v-show="scope.row.round5.length > 0  && sumEventMoney(scope.row.round5) > 0" >{{scope.row.statusMoney}}</span> 
                        {{sumEventMoney(scope.row.round5) | comma}}
                      </span>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column 
              prop="round6" 
              label="Round 6" 
              header-align="center"
              align="right">
              <template slot-scope="scope">
                <div v-if="scope.row.id !== 14">
                  <span :class="getColorMoney(scope.row.statusMoney,scope.row.round6)" >
                    <span v-show="scope.row.round6 > 0" >{{scope.row.statusMoney}}</span> 
                    {{scope.row.round6 | comma}}
                  </span>
                </div>
                <div v-else>
                  <el-popover trigger="hover" placement="top" width="300" :disabled="scope.row.round6.length === 0">
                  
                   <p v-for="(item,index) in scope.row.round6" :key="index"> <span> {{ item.becName }}</span>  
                      <span v-if="checkActionEvent(item.becId)" class="mg-l-5">{{item.action}}</span> 
                      <sup :class="checkActionEvent(item.becId)?'color-success':'color-danger'">{{' [จากรอบที่ '+item.round+']'}}</sup>
                    </p>
                    <div slot="reference" class="d-inline">
                      
                      <span  :class="getColorMoney(scope.row.statusMoney,sumEventMoney(scope.row.round6))" >
                        <span v-show="scope.row.round6.length > 0 && sumEventMoney(scope.row.round6) > 0" >{{scope.row.statusMoney}}</span> 
                        {{sumEventMoney(scope.row.round6) | comma}}
                      </span>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
        </el-table>
        <p class="text-right font-16" v-if="groupAllData && groupAllData.accounts != null && groupAllData.accounts[6] !== null && typeof groupAllData.accounts[6] != 'undefined' && typeof groupAllData.accounts[6].tradeAccountReceivables != 'undefined'">
          ลูกหนี้การค้ารอบสุดท้าย: <span :class="getColorMoney(groupAllData.accounts[6].tradeAccountReceivables>0?'+':'',groupAllData.accounts[6].tradeAccountReceivables)">฿ {{groupAllData.accounts[6].tradeAccountReceivables | comma}}</span>
        </p>
    </div>
</template>

<script>
export default {
    props:{
      eventCard:{
        type:Object,
        default(){
            return {}
        }
      },
      accounting:{
          type:Array,
          default(){
              return []
          }
      },
      calBalanceLast:{},
      loading:{
        type:Boolean,
        default(){
          return false
        }
      }
    },
    computed: {
      room(){
          return this.$store.state.room
      },
      user(){
        return this.$store.state.user
      },
      groupAllData(){
          return this.$store.state.groupAllData
      },
    },
    methods: {
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'เงินคงเหลือปลายงวด';
            return;
          } 

          let sum = (data.length > 0)?this.calBalanceLast(data,column.property,0):0

          sums[index] = this.$options.filters.comma(sum)
        });

        return sums;
    },
    getColorMoney(statusMoney,money){

      if(money === 0 || statusMoney === ''){
        return ''
      }else if(statusMoney === '+'){
        return 'color-success'
      }else{
        return 'color-danger'
      }
    },
    disbledRound(row, column, rowIndex, columnIndex){
      if(columnIndex  > this.room.currentRound){
          return 'header-row'
      }
    },
    sumEventMoney(money){
      let sum = 0
      money.map((item)=>{
        let amount = (typeof item.amount !== 'undefined')?item.amount:0
        sum = sum + amount
      })
      return sum
    },
    checkActionEvent(id){
      if(id == 10 || id == 11 || id == 12 || id == 13 || id == 14){
        return true
      }else{
        return false
      }
    },
  }
}
</script>