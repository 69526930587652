var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"width-max",attrs:{"data":_vm.inventory,"border":"","show-summary":"","summary-method":_vm.getSummaries,"cell-class-name":function (ref){
	var row = ref.row;
	var column = ref.column;
	var rowIndex = ref.rowIndex;
	var columnIndex = ref.columnIndex;

	return _vm.checkStateRound(row, column, rowIndex, columnIndex);
},"sum-text":"Total Stock","empty-text":"No Data"}},[_c('el-table-column',{attrs:{"prop":"product","label":"Product list","header-align":"center"}}),_c('el-table-column',{attrs:{"label":"Round 1","header-align":"center"}},[_c('InventoryList',{attrs:{"round":"round1","userLevel":_vm.userLevel}})],1),_c('el-table-column',{attrs:{"label":"Round 2","header-align":"center"}},[_c('InventoryList',{attrs:{"round":"round2","userLevel":_vm.userLevel}})],1),_c('el-table-column',{attrs:{"label":"Round 3","header-align":"center"}},[_c('InventoryList',{attrs:{"round":"round3","userLevel":_vm.userLevel}})],1),_c('el-table-column',{attrs:{"label":"Round 4","header-align":"center"}},[_c('InventoryList',{attrs:{"round":"round4","userLevel":_vm.userLevel}})],1),_c('el-table-column',{attrs:{"label":"Round 5","header-align":"center"}},[_c('InventoryList',{attrs:{"round":"round5","userLevel":_vm.userLevel}})],1),_c('el-table-column',{attrs:{"label":"Round 6","header-align":"center"}},[_c('InventoryList',{attrs:{"round":"round6","userLevel":_vm.userLevel}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }