<template>
    <div>
        <el-table-column
            :prop="round"
            label="Quantity"
            header-align="center"
            align="right"
            width="90">
            <template slot-scope="scope">
                <p>
                    <span v-if="typeof userLevel != 'undefined' && userLevel == 80">
                        <input class="el-input__inner text-right" >
                    </span>
                    <span v-else>{{ scope.row[round].begin }}</span>
                </p>
                <p>
                    <span v-if="typeof userLevel != 'undefined' && userLevel == 80">
                        <input class="el-input__inner text-right" >
                    </span>
                    <span v-else>{{ scope.row[round].sale }}</span>
                </p>
                <p>
                    <span v-if="typeof userLevel != 'undefined' && userLevel == 80">
                        <input class="el-input__inner text-right" >
                    </span>
                    <span v-else>{{ scope.row[round].damaged }}</span>
                </p>
                <p>
                    <span v-if="typeof userLevel != 'undefined' && userLevel == 80">
                        <input class="el-input__inner text-right" >
                    </span>
                    <span v-else>{{ scope.row[round].manufacture }}</span>
                </p>
                <p>{{ calLastAmount(scope.row[round]) }}</p>
            </template>
        </el-table-column>
        <el-table-column
            label="List"
            header-align="center"
            width="100"
            >
            <template>
                <p :class="typeof userLevel != 'undefined' && userLevel == 80 ? 'mg-y-2':''">ต้นรอบ</p>
                <p :class="typeof userLevel != 'undefined' && userLevel == 80 ? 'mg-y-2':''" class="color-danger">- ขาย</p>
                <p :class="typeof userLevel != 'undefined' && userLevel == 80 ? 'mg-y-2':''" class="color-danger">- เสียหาย</p>
                <p :class="typeof userLevel != 'undefined' && userLevel == 80 ? 'mg-y-2':''" class="color-success">+ ผลิต</p>
                <p :class="typeof userLevel != 'undefined' && userLevel == 80 ? 'mg-y-2':''" class="font-weight-bold">สิ้นรอบ</p>
            </template>
        </el-table-column>
    </div>
</template>

<script>
export default {
    props:{
        round:{
            type:String,
            default(){
                return ''
            }
        },
        userLevel:{
            type:Number,
            default(){
                return 0
            }
        }
    },
    methods: {
        calLastAmount(item){
            return item.begin - (item.sale + item.damaged) + item.manufacture 
        }
    }
}
</script>